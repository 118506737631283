import { BaseModule, Image, PageContent } from '../../types';
import React, { useState } from 'react';
import { useSiteData } from '../../providers/SiteDataProvider';
import dynamic from 'next/dynamic';
import ImageUtil from '../../util/ImageUtil';
const RichText = dynamic(() => import('../modules/RichText'));
const TextImage = dynamic(() => import('../modules/TextImage'));

const AccordionItem: React.FC<{
    item: {
        headline?: string;
        image?: Image;
        text?: string;
    };
}> = ({ item }) => {
    const { headline } = item;
    const { textUtil } = useSiteData();

    const [expanded, setExpanded] = useState(false);

    const contentWithoutHeadline = {
        ...item,
        headline: undefined,
    };

    const image = ImageUtil.getImageCompat(contentWithoutHeadline);
    return (
        <div data-t-name="AccordionItem" className="m-accordion-item">
            <div className="mo-accordion-item-inner">
                <h4 className="m-accordion-item__title" role="tab">
                    <a
                        className={expanded ? '' : 'collapsed'}
                        role="button"
                        data-toggle="collapse"
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        dangerouslySetInnerHTML={{
                            __html: textUtil.formatText(headline),
                        }}
                    />
                </h4>
                <div
                    className={
                        expanded
                            ? 'panel-collapse collapse in '
                            : 'panel-collapse collapse'
                    }
                    role="tabpanel"
                    aria-expanded={expanded}
                >
                    {!expanded ? null : (
                        <div className="m-accordion-item__content">
                            {image ? (
                                <TextImage
                                    blockEdit={true}
                                    pageContent={contentWithoutHeadline}
                                />
                            ) : (
                                <RichText
                                    blockEdit={true}
                                    pageContent={contentWithoutHeadline}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const Accordion: BaseModule = ({ pageContent, moduleHead }) => {
    return (
        <div className="accordion abstractComponent">
            <div className="m-accordion " data-t-name="Accordion">
                {moduleHead}
                <div
                    className="m-accordion__container"
                    role="tablist"
                    aria-multiselectable="true"
                >
                    {!pageContent.items
                        ? null
                        : pageContent.items.map((item, idx) => {
                              return <AccordionItem key={idx} item={item} />;
                          })}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
